import * as React from "react";
import { Field, WrappedFieldProps } from "redux-form";
import TextAreaInput from "src/components/TextArea";

type Props = {
  name: string;
  placeholder: string;
  black?: boolean;
  rows?: number;
};

const Adapter = (props: Props & WrappedFieldProps) => {
  return (
    <TextAreaInput
      {...props.input}
      black={props.black}
      rows={props.rows}
      handleChange={props.input.onChange}
      handleBlur={props.input.onBlur}
      value={props.input.value}
      placeholder={props.placeholder}
    />
  );
};

const TextAreaField = (props: Props) => (
  <Field name={props.name} props={props} component={Adapter} />
);

export default TextAreaField;
