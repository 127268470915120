import * as React from "react";
import ModalWrapper from "src/components/ModalWrapper";
import Seo from "src/components/Seo";
import ConnectFormRoute from "src/routes/ConnectForm";
import { PageProps } from "src/types";

interface Props extends PageProps {}

const ConnectForm: React.SFC<Props> = (props) => {
  return (
    <ModalWrapper>
      <Seo pageTitle="Напишите нам" />
      <ConnectFormRoute {...props} />
    </ModalWrapper>
  );
};

export default ConnectForm;
