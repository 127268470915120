import { navigate } from "@reach/router";
import * as React from "react";
import Api from "src/api";
import Typography from "src/components/Typography";
import icon from "src/images/LoginIcon.svg";
import { PageProps } from "src/types";
import styles from "./ConnectForm.module.css";
import Form from "./Form";

class ConnectForm extends React.Component<PageProps, any> {
  public render() {
    return (
      <div className={styles.connectFormWrapper}>
        <div className={styles.descriptionWrapper}>
          <div className={styles.textWrapper}>
            <Typography type="h4">
              <h3>Напишите нам</h3>
            </Typography>
            <Typography type="text">
              Опиши проблему или расскажи, что тебе не хватает на платформе
            </Typography>
          </div>
          <div className={styles.imageWrapper}>
            <img src={icon} alt="icon" />
          </div>
        </div>
        <Form onSubmit={this.handleSubmit} />
      </div>
    );
  }
  private handleSubmit = async (data: { email: string }) => {
    await Api.sendConnectForm(data);
    await navigate(this.props.location?.state?.closeTo || "/");
  };
}

export default ConnectForm;
